import React from 'react'
import Post from '../../components/Post/LatestPost/latestPost'
import styles from './PostGenerator.module.css'

const PostGenerator = props => {
  return (
    <main className={styles.container}>
      {props.state.latest === true
        ? props.props.data.Latest.edges.map(({ node }) => {
            var filtered = []
            for (var i = 0; i < props.viewData.edges.length; i++) {
              if (
                props.viewData.edges[i].node.id ===
                '/articles/' + node.slug 
              ) {
                filtered.push(props.viewData.edges[i].node)
              }
            }
            return (
              <div className={styles.post} key={node.title}>
                <Post viewData={filtered} data={node} />
              </div>
            )
          })
        : props.props.data.Oldest.edges.map(({ node }) => (
            <Post key={node.title} data={node} />
          ))}
    </main>
  )
}

export default PostGenerator
